<template>
  <div class="root">
    <div class="import-student" v-loading="loading">
      <div class="upload-view" v-if="flag == 1">
        <div class="point-view" @click.prevent="uploadHandler()">
          <i class="el-icon-upload"></i>
          <div class="">
            点击这里上传文件 <el-link type="primary" @click="downloadTemplateHandler()">下载模板</el-link>
            <div class="" v-if="postData.licenseFileName">已上传：{{ postData.licenseFileName }}</div>
          </div>
        </div>

        <el-table border :data="templateIdList">
          <el-table-column label="学员数量" prop="title" width="120"></el-table-column>
          <el-table-column :label="detailNum.studentNum" prop="isCheck">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.isCheck"
                :disabled="scope.row.isDisabled"
                @change="rowCheckboxChange()"></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="upload-fail" v-if="flag == 2">
        <i class="el-icon-circle-close"></i>
        <div class="upload-fail-title">文件校验失败</div>
        <div class="upload-fail-content">请更新文件 <el-link type="primary" @click="againHandler()">重新导入</el-link></div>

        <div class="table-list">
          <el-table :data="errorData" border v-if="errorData" style="margin: 30px 0" height="100%">
            <el-table-column label="文件行数" prop="row"></el-table-column>
            <el-table-column label="错误信息" prop="errMsg" width="200"></el-table-column>
          </el-table>
        </div>
      </div>

      <div class="upload-success" v-if="flag == 3">
        <i class="el-icon-circle-check"></i>
        <div class="upload-success-title">校验完成</div>
      </div>
    </div>
    <div class="admin-dialog-btns">
      <div class="dialog-tips">
        确认后将从余额中扣除<span style="color: #ff422d"
          >{{ postData.licenseFileName ? detailNum.studentPrice : '--' }}元 </span
        >会员服务费用
      </div>
      <div class="buttons">
        <el-button type="primary" @click="submitHandler()" :disabled="!postData.licenseFileName || flag == 2">
          确认
        </el-button>
        <el-button
          @click="
            () => {
              this.$emit('end');
            }
          "
          >关闭
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {schStudentImportStudent} from '@/api/index';
import * as XLSX from 'xlsx';
export default {
  data() {
    return {
      loading: false,
      flag: 1, // 初始值1，失败2，成功3
      postData: {},
      detailNum: {
        studentNum: '0',
        studentPrice: 0,
      },
      templateIdList: [],
      studentList: {},
      errorData: [],
    };
  },
  created() {
    if (this.$store.state.template.templateType == 1) {
      this.templateIdList.push({
        title: '补考费模板',
        templateType: 1,
        isCheck: true,
        isDisabled: true,
        rowPrice: 0,
      });
    } else if (this.$store.state.template.templateType == 3) {
      this.templateIdList.push({
        title: '补考费模板',
        templateType: 1,
        isCheck: true,
        isDisabled: true,
        rowPrice: 0,
      });
    }
  },
  methods: {
    downloadTemplateHandler() {
      window.location.href = '/downTemplate.xlsx';
    },

    againHandler() {
      this.flag = 1;
      this.errorData = [];
      this.detailNum.studentNum = '0';
      this.detailNum.studentPrice = 0;
      this.$set(this.postData, 'licenseFile', null);
      this.$set(this.postData, 'licenseFileName', null);
      this.templateIdList.forEach((item) => {
        item.rowPrice = 0;
      });
    },

    rowCheckboxChange() {
      this.detailNum.studentPrice = 0;
      this.templateIdList.forEach((item) => {
        if (item.isCheck) {
          this.detailNum.studentPrice += item.rowPrice;
        }
      });
    },

    async uploadHandler() {
      let input = document.createElement('input');
      input.type = 'file';
      input.onchange = async (ev) => {
        this.$set(this.postData, 'licenseFile', ev.target.files[0]);
        this.$set(this.postData, 'licenseFileName', this.postData.licenseFile.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, {type: 'array'});
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const results = XLSX.utils.sheet_to_json(worksheet);
          this.studentList = JSON.parse(JSON.stringify(results));
          this.detailNum.studentNum = this.studentList.length + '';
          // if (this.$store.state.template.templateType == 2) {
          //   this.templateIdList[0].rowPrice = this.$store.state.template.applyPrice * this.detailNum.studentNum;
          // } else {
          this.studentList.forEach((item) => {
            item['车型'] = item['车型'] + '';
            item['车型'].toUpperCase();
            // if (this.$store.state.template.templateType == 1) {
            this.templateIdList[0].rowPrice += parseInt(this.$store.state.template.templateDict[`${item['车型']}Price`]);
            // } else {
            //   this.templateIdList[0].rowPrice += this.$store.state.template.templateDict[`${item['车型']}Price`];
            //   this.templateIdList[1].rowPrice = this.$store.state.template.applyPrice * this.detailNum.studentNum;
            // }
          });
          // }
          this.detailNum.studentPrice = parseInt(this.templateIdList[0].rowPrice);
          if (isNaN(this.templateIdList[0].rowPrice)) {
            this.$message.error('上传文件有误，请核查后重新上传！');
            this.detailNum.studentNum = '0';
            this.detailNum.studentPrice = 0;
            this.$set(this.postData, 'licenseFile', null);
            this.$set(this.postData, 'licenseFileName', null);
            this.templateIdList.forEach((item) => {
              item.rowPrice = 0;
            });
            return false;
          }
        };
        reader.readAsArrayBuffer(ev.target.files[0]);

        input.remove();
      };
      input.click();
    },

    // readerData(rawFile) {
    //   this.loading = true;
    //   return new Promise((resolve, reject) => {
    //     void reject;
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       const data = e.target.result;
    //       const workbook = XLSX.read(data, {type: 'array'});
    //       const firstSheetName = workbook.SheetNames[0];
    //       const worksheet = workbook.Sheets[firstSheetName];
    //       const header = this.getHeaderRow(worksheet);
    //       const results = XLSX.utils.sheet_to_json(worksheet);
    //       this.generateData({header, results});
    //       this.loading = false;
    //       resolve();
    //     };
    //     reader.readAsArrayBuffer(rawFile);
    //   });
    // },

    submitHandler() {
      if (this.flag == 3) {
        this.$emit('success');
        this.$emit('end');
        return false;
      }
      if (!this.postData.licenseFileName) {
        this.$message.warning('请选择要上传的文件！');
        return false;
      }
      var type = null;
      // if (this.templateIdList.length > 1) {
      //   if (!this.templateIdList[0].isCheck && !this.templateIdList[1].isCheck) {
      //     this.$message.error('请至少选中一个会员服务产品');
      //     return false;
      //   } else if (this.templateIdList[0].isCheck && !this.templateIdList[1].isCheck) {
      //     type = 1;
      //   } else if (!this.templateIdList[0].isCheck && this.templateIdList[1].isCheck) {
      //     type = 2;
      //   } else {
      //     type = 3;
      //   }
      // } else
      if (this.templateIdList.length == 1) {
        if (!this.templateIdList[0].isCheck) {
          this.$message.error('请至少选中一个会员服务产品');
          return false;
        } else {
          type = this.templateIdList[0].templateType;
        }
      }
      if (this.flag == 1) {
        let formData = new FormData();
        formData.append('file', this.postData.licenseFile);
        formData.append('policyContractType', type);
        schStudentImportStudent(formData).then((res) => {
          if (res.data.code == 0) {
            this.flag = 3;
            this.$emit('success');
          } else {
            this.flag = 2;
            this.$set(this.postData, 'licenseFile', null);
            this.$set(this.postData, 'licenseFileName', null);
            if (res.data.data && res.data.data.length) {
              this.errorData = res.data.data;
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.root {
  .import-student {
    .upload-view {
      height: 400px;
      .point-view {
        text-align: center;
        margin: 120px auto 30px;
        font-size: 16px;
        cursor: pointer;
      }
      .el-table {
        width: 300px;
        margin: 0 auto;
      }
      .el-icon-upload {
        font-size: 60px;
      }
    }

    .upload-fail {
      text-align: center;
      margin: 80px auto;
      width: 300px;
      font-size: 16px;
      .table-list {
        height: 300px;
      }
      .el-icon-circle-close {
        font-size: 80px;
        color: $--color-danger;
      }
      .upload-fail-title {
        font-size: 18px;
        margin: 5px 0 8px;
        color: $--color-danger;
      }
    }

    .upload-success {
      text-align: center;
      margin: 180px auto;
      width: 240px;
      font-size: 16px;
      .el-icon-circle-check {
        font-size: 80px;
        color: $--color-primary;
      }
      .upload-success-title {
        font-size: 20px;
        margin: 5px 0 8px;
        color: $--color-primary;
      }
    }
  }
  .admin-dialog-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dialog-tips {
      font-size: 18px;
    }
  }
}
</style>
